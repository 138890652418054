<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal @success="getAll" />

        <div class="flex items-end justify-between border-b border-gray-400 mb-3 pb-3">
            <el-dropdown>
                <el-button type="primary">
                    {{ $t('common.menu') }} <i class="el-icon-arrow-down el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <router-link :to="{ name: 'managers_archived' }">
                        <el-dropdown-item>
                            {{ $t('managers.archived') }}
                        </el-dropdown-item>
                    </router-link>
                </el-dropdown-menu>
            </el-dropdown>
            <el-input v-model="quickSearch" suffix-icon="el-icon-search" class="w-72" :placeholder="$t('common.search')" clearable />
        </div>

        <ManagersAllTable :data="managersData" :dataOrigin="managersDataOrigin" @handleArchive="handleArchive" />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
export default {
    components: {
        ManagersAllTable: () => import(/* webpackChunkName: "Managers/ManagersAllTable" */ './components/ManagersAllTable'),
    },

    data() {
        return {
            noDataInformation:  false,
            quickSearch:        '',
            managersData:       [],
            managersDataOrigin: [],
        };
    },

    watch: {
        quickSearch() {
            this.filter();
        },
    },

    beforeCreate() {
        this.$wait.start('loading.initial_managers');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('managers.all_managers'));
        this.$store.commit('setActiveMenuItem', '4-1');

        this.getAll();
    },

    methods: {
        async getAll() {
            this.quickSearch = '';
            this.$wait.start('loading');
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/managers`);
                this.managersData = Object.freeze(data);
                this.managersDataOrigin = Object.freeze(data);
                this.noDataInformation = data.length;
                this.$wait.end('loading');
                this.$wait.end('loading.initial_managers');
            } catch (e) {
                this.$wait.end('loading');
                this.$wait.end('loading.initial_managers');
            }
        },

        handleArchive(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl: `${process.env.VUE_APP_CORE_API_URL}/managers/${uuid}`,
            });
        },

        filter() {
            this.managersData = this.managersDataOrigin.filter(manager => (
                manager.name.toLowerCase().includes(this.quickSearch.toLowerCase())
                || manager.surname.toLowerCase().includes(this.quickSearch.toLowerCase())
            ));
        },
    },
};
</script>
<style scoped>
    .el-input{
        width: 18rem;
    }
    .el-button--mini {
        padding: 5px 5px;
    }
</style>
